import Header from "../header/Header";


const Layout = () => {
    return(
        <div>
            <Header />
        </div>
    );
}

export default Layout;