import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Layout from './components/layout/Layout';

import './styles/normalize.css';
import './styles/vars.css';
import './styles/default.css';
import './styles/utilities.css';
import AuthProvider from './context/AuthProvider';

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        path: '/*',
        element: <Layout />
      }
    ]
  }
]);

export default function Root(props) {

  return(
    <RouterProvider 
      router={router}
    />
  );
}
