// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --black: #33373B;
    --gray: #E1E1E1;
    --grayLight: #F2F5F8;
    --grayLetter: #5F6368;
    --green: #64A50B;
}`, "",{"version":3,"sources":["webpack://./src/styles/vars.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,qBAAqB;IACrB,gBAAgB;AACpB","sourcesContent":[":root{\r\n    --black: #33373B;\r\n    --gray: #E1E1E1;\r\n    --grayLight: #F2F5F8;\r\n    --grayLetter: #5F6368;\r\n    --green: #64A50B;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
