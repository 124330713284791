// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    box-sizing: border-box;
    font-size: 62.5%;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body{
    font-size: 1.6rem;
    color: var(--black);
    font-family: "Outfit", sans-serif;
}

img{
    max-width: 100%;
}

a{
    display: block;
    text-decoration: none;
    color: var(--black);
}

button{
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
}

button:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/default.css"],"names":[],"mappings":"AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');\r\n\r\nhtml {\r\n    box-sizing: border-box;\r\n    font-size: 62.5%;\r\n}\r\n*, *:before, *:after {\r\n    box-sizing: inherit;\r\n}\r\n\r\nbody{\r\n    font-size: 1.6rem;\r\n    color: var(--black);\r\n    font-family: \"Outfit\", sans-serif;\r\n}\r\n\r\nimg{\r\n    max-width: 100%;\r\n}\r\n\r\na{\r\n    display: block;\r\n    text-decoration: none;\r\n    color: var(--black);\r\n}\r\n\r\nbutton{\r\n    border: none;\r\n    outline: none;\r\n    background-color: transparent;\r\n    padding: 0;\r\n}\r\n\r\nbutton:hover{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
