// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.HVj_kkrtNUctms0WsaLq{
    position: relative;
    display: flex;
    align-items: center;
    height: 5rem;
    padding: 0 1.5rem;
    border-left: 1px solid var(--gray);
    transition: .3s ease background-color;
    &:last-of-type{
        border-right: 1px solid var(--gray);
    }

    &:hover{
        background-color: var(--grayLight);
    }
}

.klM12cKakG4JVB95fnyE{
    position: absolute;
    height: .5rem;
    width: 100%;
    background-color: var(--green);
    bottom: 0;
    left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/nav-link/NavLink.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kCAAkC;IAClC,qCAAqC;IACrC;QACI,mCAAmC;IACvC;;IAEA;QACI,kCAAkC;IACtC;AACJ;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,SAAS;IACT,OAAO;AACX","sourcesContent":["\r\n.link{\r\n    position: relative;\r\n    display: flex;\r\n    align-items: center;\r\n    height: 5rem;\r\n    padding: 0 1.5rem;\r\n    border-left: 1px solid var(--gray);\r\n    transition: .3s ease background-color;\r\n    &:last-of-type{\r\n        border-right: 1px solid var(--gray);\r\n    }\r\n\r\n    &:hover{\r\n        background-color: var(--grayLight);\r\n    }\r\n}\r\n\r\n.select{\r\n    position: absolute;\r\n    height: .5rem;\r\n    width: 100%;\r\n    background-color: var(--green);\r\n    bottom: 0;\r\n    left: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `HVj_kkrtNUctms0WsaLq`,
	"select": `klM12cKakG4JVB95fnyE`
};
export default ___CSS_LOADER_EXPORT___;
