import { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

export interface context{
    page: string;
}

const defaultState = {
    page: ''
}

export const AuthContext = createContext<context>(defaultState);

const AuthProvider = () => {
    const location = useLocation();
    const [page, setPage] = useState('');

    useEffect(() => {
        const getLocation = () => {
            const path = location.pathname.split('/');
            if(path.length > 2){
                setPage(`/${path[1]}/${path[2]}`);
            }
            
        }
        getLocation();
    }, [location.pathname]);


    return(
        <AuthContext.Provider
            value={{
                page
            }}
        >
            <Outlet />
        </AuthContext.Provider>
    );
}

export default AuthProvider;