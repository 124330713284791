import Logo from '../../assets/logo-little.svg';
import NavLink from '../nav-link/NavLink';
import styles from './Header.module.css';

const Header = () => {
    return(
        <header className={styles.header}>
            <img 
                src={Logo}
                width={35}
                height={35}
                alt="Logo"
                className={styles.img}
            />
            <nav className={styles.nav}>
                <NavLink 
                    link="/portal/administracion"
                    label="Administración"
                />
                <NavLink 
                    link="/portal/adquisicion"
                    label="Adquisición"
                />
                <NavLink 
                    link="/portal/atencion"
                    label="Atención"
                />
                <NavLink 
                    link="/portal/auditoria"
                    label="Auditoría"
                />
            </nav>
        </header>
    );  
}

export default Header;