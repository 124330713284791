// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

._UEA_dfzSk0x7MUqIxn3{
    display: flex;
    align-items: center;
    height: 5rem;
    border-bottom: 1px solid var(--gray);
    padding: 0 1rem 0 1.5rem;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #FFF;
}

.m1I_7G2zHdi44rJvpmDg{
    margin-right: 1.5rem;
}

.ZhikwfJieaWis6P1dFqs{
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Header.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,oCAAoC;IACpC,wBAAwB;IACxB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["\r\n\r\n.header{\r\n    display: flex;\r\n    align-items: center;\r\n    height: 5rem;\r\n    border-bottom: 1px solid var(--gray);\r\n    padding: 0 1rem 0 1.5rem;\r\n    overflow-x: auto;\r\n    overflow-y: hidden;\r\n    background-color: #FFF;\r\n}\r\n\r\n.img{\r\n    margin-right: 1.5rem;\r\n}\r\n\r\n.nav{\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `_UEA_dfzSk0x7MUqIxn3`,
	"img": `m1I_7G2zHdi44rJvpmDg`,
	"nav": `ZhikwfJieaWis6P1dFqs`
};
export default ___CSS_LOADER_EXPORT___;
