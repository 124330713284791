import { Link, useLocation } from "react-router-dom";
import styles from './NavLink.module.css';
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

interface Props{
    link: string;
    label: string;
}

const NavLink = ({link, label}: Props) => {
    const {page} = useAuth();

    return(
        <Link
            to={link}
            className={styles.link}
        >
            <span>{label}</span>
            <div className={`${page === link ? styles.select : ''}`}></div>
        </Link>
    );
}

export default NavLink;